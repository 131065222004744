.address p {
    color: black;
    font-size: 18px;
    /* text-align: left; */
    margin-bottom: 0;
}

.address {
    max-width: 1000px;
}

.address .title {
    font-size: 24px;
    margin-top: 1rem;
    margin-bottom: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
}

.Contact h1 {
    font-size: 60px;
    text-align: center;
    color: black;
}

.Contact a {
    color: black;
    font-weight: 500;
}

.Contact .subscribe-container {
    width: 100%;
    margin: auto;
    max-width: 700px;
}

.Contact .section-block {
    max-width: 1000px;
    margin: auto;
}

.button-container {
    display: flex;
    justify-content: center;
}

@media only screen and (max-width: 1000px) {
    .address p {
      text-align: center;
    }

    .map {
        margin: 2rem;
    }

    .section-block {
        margin-left: 1rem;
        margin-right: 1rem;
    }
  }
