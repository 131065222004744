.footer {
    padding: 0;

    .footer-up {
        border-bottom: 1px solid #D9AB50;
        padding: 85px 0 98px;
        display: flex;
        justify-content: space-between;
        width: 100%;

        ul {
            padding: 0;

            li {
                list-style: none;
                margin-bottom: 14px;

                a {
                    display: flex;
                    align-items: center;
                    color: white;
                    font-size: 1rem;
                    font-weight: 700;

                    svg {
                        margin-right: 15px;
                    }
                }

                a.link-title {
                    font-size: 1.125rem;
                    font-weight: 700;
                    color: #D9AB50;
                    margin-bottom: 20px;
                }
            }
        }
    }

    .copyright {
        padding: 38px 0 40px;
        p {
            font-size: .875rem;
            line-height: 1.357;
            color: white;            
            font-weight: 400;
            margin: 0;
        }
    }

        
}