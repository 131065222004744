@import "../../../assets/css/sass/variables";
@import "../../../assets/css/sass/mixins";

.home-section-2 {
    .title {
        margin-bottom: 50px !important;

        h2 {
            font-size: 60px !important;
        }
        
        p {
            font-size: 22px !important;
            max-width: 712px;
        }
    }

    .content {
        .mockup-wrapper {
            @include respond-below(xl) {
                display: none;
            }
        }

        .mockup-card {
            padding: 1rem;
            display: flex;
            align-items: center;
            cursor: pointer;
            border-radius: 8px;
            justify-content: space-between;

            div {
                padding-right: 1rem;

                h3 {
                    font-weight: 700;
                    font-size: 1.25rem;
                    line-height: 1.2;
                    margin-bottom: 13px;
                }

                p {
                    font-size: 1.075rem;
                    line-height: 1.357;
                    color: $text-color;
                    font-weight: 400;
                    margin-bottom: 0;
                }
            }

            &.right {
                flex-direction: row-reverse;

                div {
                    padding-right: 0;
                    padding-left: 4rem;
                }
            }

            img {
                width: 70px;
            }

            &.active {
                box-shadow: 5px 5px 15px 5px #dcdcdc;
            }

            @include respond-below(xl) {
                flex-direction: row !important;

                div {
                    order: 2;
                    padding-left: 1rem !important;
                    padding-right: 0 !important;
                    height: 100%;
                }

                img {
                    order: 1;
                }
            }
        }

        .mockup-img-wrapper {
            padding: 0 15px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .slick-container {
            display: none;

            .slick-list {
                padding: 0 40px 0 0 !important;
            }

            .slick-mockup {
                padding: 15px 5px;
                height: 100%;
            }
            .mockup-card {
                border: 2px solid #F9F9F9;
                box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.14);
                height: 100%;

                &.active {
                    border: 2px solid #F2BB1E;
                }
            }

            .slick-track {
                display: flex;
            }
            .slick-track .slick-slide {
                display: flex;
                height: auto;
                justify-content: center;
            }

            .slick-arrow {
                display: none !important;
            }

            @include respond-below(xl) {
                display: block;
            }
        }

        .footer {
            text-align: center;
            margin-top: 38px;
        }

        @include respond-below(md) {
            padding-left: 1rem;
            padding-right: 1rem;
        }

        img {
            max-width: 115%;
        }
    }
}

.container {
    max-width: 1800px !important;
}
