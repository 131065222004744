@import "../../../assets/css/sass/mixins";

.home-section-3 {
    .content {
        .img-wrapper {
            padding: 11px 15px 37px;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                width: 907px;
            }
        }
        
    }
    .title {
        h2 {
            font-size: 60px !important;
        }

        p {
            font-size: 22px !important;
        }
    }
    

    .footer {
        text-align: center;
    }
}
