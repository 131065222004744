.PrivacyPolicy {
	padding: 40px;
}

.PrivacyPolicy h1 {
	font-size: 60px;
	color: black;
	text-align: center;
}

.PrivacyPolicy h3 {
	font-size: 30px;
	color: black;
	font-weight: bold;
}
