@import "../../assets/css/sass/mixins";

.modal-overlay {
  background-color: rgba(black, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  animation: fadeIn 500ms forwards;

  .modal-inner {
    width: 609px;
    max-width: 100%;
    border-radius: 10px;
    background-color: white;
    padding: 50px;
    position: relative;
    animation: slideUp 500ms forwards;

    @include respond-below(md) {
      padding: 40px 15px;
      max-width: 90%;
      max-height: 90%;
    }

    .btn-close {
      position: absolute;
      top: 15px;
      right: 15px;
      border: none;

      @include respond-below(md) {
        top: 5px;
        right: 5px;
      }
    }

    .btn-back {
      position: absolute;
      top: 15px;
      left: 15px;
      border: none; 

      @include respond-below(md) {
        top: 5px;
        left: 5px;
      }
    }

    &[data-stage="2"] {
      h2 {
        margin-bottom: 10px;
      }

      .sub-text {
        max-width: 50%;
        margin: 0 auto;
        font-size: 16px;
        line-height: calc(22/16);
      }

      .qr-code {
        margin: 20px auto;
        display: block;
        width: 222px;
        height: auto;
        max-width: 100%;
      }
    }

    &[data-stage="3"] {
      .ant-input {
        height: 50px;
      }
    }

    &[data-stage="4"] {
      .form-col {
        margin-bottom: 20px;

        .ant-input {
          height: 50px;
        }

        .ant-select {
          margin: 0;
        }
      }
    }

    &[data-stage="5"] {
      h2 {
        margin-bottom: 20px;
      }

      .sub-text {
        margin-bottom: 20px;
      }
    }

    h2 {
      font-weight: 700;
      font-size: 40px;
      line-height: calc(48/40);
      color: #262626;
      margin: 0 0 40px;
      text-align: center;

      @include respond-below(md) {
        font-size: 32px;
      }
    }

    h4 {
      text-align: center;
    }

    .ant-select {
      margin: 0 0 20px;

      .ant-select-selection--single {
        height: 50px;
      }

      .ant-select-selection__rendered {
        line-height: 50px;
      }
    }

    .app-types {
      .type {
        @include respond-below(md) {
          &:not(:last-child) {
            margin-bottom: 20px;
          }
        }
        input[type="radio"] {
          display: none;

          &:checked + label {
            div::after {
              content: "";
              display: block;
              position: absolute;
              top: 15%;
              left: 15%;
              border-radius: 50%;
              width: 70%;
              height: 70%;
              background-color: #1990FF;
            }
          }
        }

        label {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          background-color: #F9F9F9;
          border: 1px solid #E9E9E9;
          border-radius: 5px;
          padding: 13px 11px;
          cursor: pointer;

          img {
            margin: 0 15px 0 0;
          }

          span {
            font-size: 12px;
            line-height: calc(19/12);
            color: #595959;
          }

          div {
            border-radius: 50%;
            width: 24px;
            height: 24px;
            background-color: #FFFFFF;
            border: 1px solid #E9E9E9;
            margin-left: auto;
            position: relative;
          }
        }
      }
    }

    .text-center {
      text-align: center;
    }

    button.ant-btn.ant-btn-primary {
      margin: 32px auto;
      font-size: 14px;
      font-weight: 700;
      min-width: 230px;
      max-width: 100%;
      height: 40px;
      background-color: #1990FF;
      border-color: #1990FF;
      box-shadow: none;
    }

    p {
      font-size: 14px;
      text-align: center;
      line-height: calc(19/14);
      margin: 0;

      button.ant-btn.ant-btn-link {
        padding: 0;
      }
    }
  }
}

.ant-select-dropdown-menu {
  .ant-select-dropdown-menu-item:first-child {
    border-bottom: 1px solid #E9E9E9;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-bottom: 10px;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideUp {
  from {
    transform: translateY(50px);
  }
  to {
    transform: translateY(0);
  }
}

.user-info-form {
  display: flex;
  flex-direction: column;
  align-items: center;

  .form-col {
    margin-top: 10;
    padding-top: 10px;
  }
}

.ant-input::placeholder {
  color: #4D4D4D !important;
}

.ant-select-selection-selected-value {
  color: #4D4D4D !important;
}